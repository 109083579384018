<template>
  <div>
    <el-form-item prop="competitor">
      <el-input
        class="form-input"
        type="text"
        v-model="competitor"
        autocomplete="off"
        placeholder="Nombre completo de quien sigue la trasmisión"
        @input="sendValue(1)"
      ></el-input>
    </el-form-item>
    <el-form-item prop="competitor_phone">
      <el-input
        class="form-input"
        type="text"
        v-model="competitor_phone"
        autocomplete="off"
        placeholder="Número celular de quien sigue la trasmisión"
        @input="sendValue(2)"
      ></el-input>
    </el-form-item>
    <!-- <el-form-item prop="beneficiary3">
      <el-input
        class="form-input"
        type="number"
        v-model="beneficiary3"
        autocomplete="off"
        placeholder="Documento del beneficiario 3"
        @input="sendValue(3)"
      ></el-input>
    </el-form-item> -->
  </div>
</template>

<script>
export default {
  name: "Competitor",
  props: ['reset'],
  watch: {
    reset: function(val) {
      if(val){
        this.competitor="";
        this.competitor_phone="";
      }
    },
  },
  data() {
    return {
      competitor: "",
      competitor_phone: "",
    };
  },
  methods: {
    sendValue(op) {
      let val = this.competitor;
      if (op === 2) {
        val = this.competitor_phone;
      }
      this.$emit("set-fields", {
        field: "competitor" + op,
        value: val,
      });
    },
  },
};
</script>