<template>
  <div>
    <el-dialog
      width="23%"
      top="40px"
      :visible.sync="modalOpenSerial"
      :before-close="closeModal"
      :destroy-on-close="true"
    >
      <div slot="title">
        <i class="el-icon-setting"></i> Configurar campos de tablas descargadas
      </div>
      <el-alert
        title="Puedes habilitar los campos que se mostrarán en las tablas que descarguen los jugadores"
        type="info"
        show-icon
        style="margin-bottom: 10px"
      >
      </el-alert>
      <!-- el-icon-tickets -->
      <el-form
        v-loading="loadingForm"
        :model="form"
        size="mini"
        ref="form"
        label-width="200px"
        class="demo-form"
      >
        <el-form-item label="Fecha de juego" prop="serial">
          <el-switch v-model="form.date_game" @change="updateConfig">
          </el-switch>
        </el-form-item>
        <el-form-item label="Nombre de jugador" prop="serial">
          <el-switch v-model="form.name_player" @change="updateConfig"> </el-switch>
        </el-form-item>
        <el-form-item label="Mostrar URL de transmisión" prop="serial">
          <el-switch v-model="form.url_transmission" @change="updateConfig"> </el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" icon="el-icon-close" @click="closeModal"
          >Cancelar</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getSerialById, updateTableConfig } from "@/api/serial";
export default {
  name: "ModalSettings",
  props: ["modalOpenSerial", "serial_id", "config"],
  watch: {
    serial_id: function (val) {
      if (val) {
        this.getSerial();
      }
    },
  },
  data() {
    return {
      loadingForm: false,
      form: {
        date_game: false,
        name_player: false,
        url_transmission: false,
      },
    };
  },
  methods: {
    updateConfig() {
      this.loadingForm = true;
      updateTableConfig(this.serial_id, this.form)
        .then(({ data }) => {
          this.loadingForm = false;
        })
        .catch(function (error) {
          this.loadingForm = false;
          console.log(error);
        });
    },
    getSerial() {
      this.loadingForm = true;
      getSerialById(this.serial_id)
        .then(({ data }) => {
          let configTables = JSON.parse(data.config_table);
          this.form = {
            date_game: (configTables !== null) ? configTables.date_game : false,
            name_player: (configTables !== null) ? configTables.name_player : false,
          };
          console.log(configTables);
            this.loadingForm = false;
        })
        .catch(function (error) {
          console.log(error);
          this.loadingForm = false;
        });
    },
    closeModal() {
      this.$emit("close-settings");
    },
  },
};
</script>
<style lang="scss" scope>
.line {
  text-align: center;
}
.mb-0 {
  margin-bottom: 0 !important;
}
</style>