<template>
  <div>
    <el-input
      class="form-input"
      type="text"
      v-model="nameCompany"
      autocomplete="off"
      placeholder="Empresa"
      @input="sendValue"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: "Company",
  data() {
    return {
      nameCompany: "",
    };
  },
  methods: {
    sendValue() {
      this.$emit("set-fields", {field:'name_company' ,value: this.nameCompany});
    },
  },
};
</script>