<template>
  <div>
    <el-dialog
      title="Listado de Tablas"
      width="65%"
      top="20px"
      :visible.sync="modalOpen"
      :before-close="closeModal"
      :destroy-on-close="true"
    >
    <div style="height: 700px;">
      <tables-list :serial_id="serial_id" :show="modalOpen"></tables-list>
    </div>
    </el-dialog>
  </div>
</template>
<script>
import TablesList from "../../../views/apps/modules/TablesList.vue";
// import TablesList from "../../../views/apps/modules/TablesList2.vue";
// import TablesList from "../../../views/apps/modules/TablesList3.vue";
export default {
  name: "modalTables",
  components: {
    TablesList,
  },
  props: ["modalOpen", "serial_id"],
  data() {
    return {
      show:false
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
  .el-dialog__body{
    padding-top: 0px;
  }
</style>