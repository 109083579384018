<template>
  <div>
    <el-select
      v-model="zone"
      placeholder="Zona"
      value-key="id"
      @change="sendValue"
    >
      <el-option
        v-for="item in zones"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "ZoneSelect",
  props: ['reset'],
  watch: {
    reset: function(val) {
      if(val){
        this.zone="";
      }
    },
  },
  created() {
    // ORDENAR ALFABETICAMENTE EL ARREGLO zonas
    this.zones = this.zones.sort(this.SortArray);
  },
  data() {
    return {
      zone: "",
      zones: [
        { id: 1, name: "Antioquia" },
        { id: 2, name: "Centro" },
        { id: 3, name: "Costa" },
        { id: 4, name: "Eje Cafetero" },
        { id: 5, name: "Occidente" },
      ],
    };
  },
  methods: {
    sendValue() {
      this.$emit("set-fields", {
        field: "name_zone",
        value: this.zone,
      });
    },
    SortArray(x, y) {
      return x.name.localeCompare(y.name);
    },
  },
};
</script>

<style lang="scss">
.el-select {
  width: 100%;
}
</style>