<template>
  <vue-scroll class="register-page align-vertical">
    <div
      class="form-wrapper align-vertical-middle"
      v-loading="loading_table"
      :element-loading-text="text_loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- <el-button @click="print"> Imprimir tabla </el-button> -->
      <img class="image-logo" src="@/assets/images/logo-bingo.png" alt="logo" />
      <div
        v-if="show && !print_page"
        class="form-box card-base card-shadow--extraLarge"
      >
        <h1 class="title-agency">{{ agency.name }}</h1>
        <el-form
          :model="form"
          :rules="rules"
          size="small"
          ref="form"
          class="demo-form"
          status-icon
          v-loading="loading"
        >
          <el-form-item prop="nit">
            <float-label class="styled">
              <el-input
                class="form-input"
                type="text"
                v-model="form.nit"
                autocomplete="off"
                placeholder="Documento de Identidad"
              ></el-input>
            </float-label>
          </el-form-item>
          <el-form-item prop="name_player">
            <float-label class="styled">
              <el-input
                class="form-input"
                type="text"
                v-model="form.name_player"
                autocomplete="off"
                placeholder="Nombre Completo"
              ></el-input>
            </float-label>
          </el-form-item>
          <!-- <el-form-item prop="email">
            <float-label class="styled">
              <el-input
                class="form-input"
                type="email"
                v-model="form.email"
                autocomplete="off"
                placeholder="E-mail"
              ></el-input>
            </float-label>
          </el-form-item> -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="phone">
                <float-label class="styled">
                  <el-input
                    class="form-input"
                    type="tel"
                    v-model.number="form.phone"
                    autocomplete="off"
                    placeholder="Teléfono"
                    ref="phone"
                  ></el-input>
                </float-label>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="city_id">
                <float-label class="styled">
                  <el-select
                    v-model="form.city_id"
                    clearable
                    filterable
                    placeholder="Ciudad"
                  >
                    <el-option
                      v-for="item in cities"
                      :key="item.id"
                      :label="item.description"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </float-label>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="polices" style="text-align: left">
            <el-checkbox-group v-model="form.polices">
              <el-checkbox name="polices">
                Acepto las
                <a
                  href="https://indervalle.gov.co/wp-content/uploads/2020/09/CONDICIONES-DEL-BINGO-1-convertido.pdf"
                  target="_blank"
                  style="text-decoration: underline"
                  >politicas del evento</a
                >
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>

        <div class="flex text-center center">
          <el-button
            plain
            type="primary"
            @click="save('form')"
            icon="el-icon-check"
            >Registrar</el-button
          >
        </div>

        <div style="padding-top: 20px; font-size: 12px; text-align: center">
          Desarollado by
          <a href="https://aplicacionesexcel.com" target="_blank">AplexTM</a>
        </div>
      </div>
      <div v-if="loading_game" class="loading-game">
        <i class="el-icon-loading"></i> Validando juegos disponibles...
      </div>

    </div>
  </vue-scroll>
</template>
<script>
import {
  getAvailablePlay,
  store,
  getPlayer,
  getCities,
  getTable,
} from "@/api/player";
import { setPlayer } from "@/utils/player";
import {
  validateEmail,
  resendEmail,
  validateNit,
  validateCodeAgency,
  downloadTable
} from "@/api/player";
import { login } from "@/api/login";
export default {
  name: "PlayerAgency",
  data() {
    var validate_nit = (rule, value, callback) => {
      if (value === null || value === "") {
        callback(new Error("Campo obligatorio"));
      } else if (value.length < 6) {
        callback(new Error("Caracteres minimos 7"));
      } else if (/\D/.test(value)) {
        callback(new Error("Debe contener solo numeros"));
      } else {
        if (this.send) {
          validateNit({ nit: value }).then(({ data }) => {
            if (!data.valid) {
              callback(new Error(data.message));
            }
            callback();
          });
        } else {
          callback();
        }
      }
    };
    return {
      loading: false,
      loading_table: false,
      loading_game: true,
      show: false,
      hide: false,
      print_page: false,
      agency: {
        name: "",
      },
      nit_player: "",
      text_loading: "Asignando tabla...",
      options: [],
      city: {},
      cities: [],
      list: [],
      send: false,
      form: {
        nit: "",
        name_player: "",
        email: "",
        phone: "",
        city_id: "",
        polices: true,
        create_table: true,
      },
      rules: {
        nit: [{ validator: validate_nit }],
        name_player: [
          {
            required: true,
            message: "Por favor ingrese su nombre completo",
          },
        ],
        phone: [
          {
            required: true,
            message: "Ingrese su teléfono",
          },
          { type: "number", message: "Número invalido" },
        ],
        city_id: [
          {
            required: true,
            message: "Seleccione la ciudad",
          },
        ],
        polices: [
          {
            required: true,
            message: "Por favor acepte las politicas",
            trigger: ["change"],
          },
        ],
      },
      // DATA TABLE BINGO
      table_bingo: "",
      bingo_b: [],
      bingo_i: [],
      bingo_n: [],
      bingo_g: [],
      bingo_o: [],
    };
  },
  beforeMount() {
    // validar si existe agencia
    this.validateAgency();
  },
  mounted() {
    this.getCity();
  },
  methods: {
    downloadTable(tables) {
      downloadTable(tables)
        .then(({ data }) => {
          this.downloadFile(data, "tabla-" + tables[0].table_bingo);
        })
        .catch(function (error) {
          this.$message.error("Error.");
          console.log(error);
        });
    },
    downloadFile(response, filename) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = filename + ".pdf";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    save(formName) {
      this.loading = true;
      this.send = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveForm();
        } else {
          this.send = false;
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveForm() {
      let me = this;
      store(this.form)
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 200) {
            this.table_bingo = data.table.player.bingo[0].table_bingo;
            // this.loading = false;
            console.log(data);
            // this.generateTableBingo(data.table);
            this.resetForm("form");
            // this.print_page = true;
            me.$swal
              .fire({
                icon: "success",
                title: "Registro exitoso!",
                html:
                  "Por favor descarga la tabla #<strong>" +
                  data.table.player.bingo[0].table_bingo +
                  "</strong> haciendo click en el boton 'Descargar Tabla'.",
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText:
                  'Descargar Tabla <i class="el-icon-download"></i>',
                  cancelButtonText: 'Cerrar'
              })
              .then((result) => {
                if (result.isConfirmed) {
                  me.downloadTable(data.table.player.bingo);
                }
              });
          } else {
            this.loading = false;
            if (data.code === 700) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                allowOutsideClick: false,
                text: data.table.table_bingo,
                confirmButtonText: "Cerrar",
              });
            } else {
              this.$message.error("Error! " + data.error.errorInfo[2]);
            }
          }
        })
        .catch(function (error) {
          this.loading = false;
          this.loading = false;
          console.log("Err: ", error);
        });
    },
    getCity() {
      let me = this;
      getCities()
        .then(function (response) {
          console.log(response);
          me.cities = response.data;
        })
        .catch(function (error) {
          console.log(error);
          toastr.warning("Error: -" + error);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.city = {};
      this.loading = false;
    },
    validateAvaliablePlay() {
      // this.hide = true;
      // this.loading_game = false;
      getAvailablePlay()
        .then(({ data }) => {
          this.loading_game = false;
          if (data.data) {
            this.show = true;
          } else {
            this.hide = true;
          }
        })
        .catch(function (error) {
          console.log("Err: ", error);
        });
    },
    validateAgency() {
      let me = this;
      var code_agency = this.$route.params;
      validateCodeAgency(code_agency)
        .then(({ data }) => {
          if (data) {
            this.agency = data;
            this.form.code = data.code;
            this.validateAvaliablePlay();
          } else {
            this.$router.push({ path: "/404" });
          }
        })
        .catch(function (error) {
          console.log("Error: ", error.response);
          me.$swal.fire({
            icon: "error",
            title: "Error: " + error.response.status,
            allowOutsideClick: false,
            text: error.response.data.message,
            confirmButtonText: "Cerrar",
          });
        });
    },

  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.register-page {
  // margin-left: -30px;
  // margin-right: -30px;
  .title-agency {
    margin: 0 auto;
    text-align: center;
  }
  .loading-game {
    color: aliceblue;
    font-size: 25px;
    margin-top: 50px;
    font-weight: 900;
    text-align: center;
  }
  .return-play {
    font-size: 15px;
    color: #ffee78;
  }

  .no-play {
    color: aliceblue;
    border: 2px solid #bebcbc;
    border-radius: 30px;
    font-size: 30px;
    font-weight: 900;
    margin: 0 auto;
    margin-bottom: 60px;
    padding: 40px;
    margin-top: 50px;
    width: 400px;
    -webkit-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    -moz-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
  }

  .divider {
    margin: 15px 0;
  }
  .styled {
    // margin-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .accept {
    margin-top: 20px;
  }
  .form-wrapper {
    width: 100%;
  }

  .image-logo {
    width: 300px;
    margin: 0px auto;
    margin-top: 60px;
    display: block;
  }
  .form-box {
    // background: transparent;
    width: 100%;
    max-width: 340px;
    padding: 30px;
    box-sizing: border-box;
    margin: 20px auto;
    padding-bottom: 10px;

    a {
      font-size: 14px;
      color: $text-color-accent;
      text-decoration: none;
      font-weight: 500;
    }

    .signin-btn {
      width: 160px;
    }

    .login-box {
      font-size: 14px;
    }
  }
  @media (max-width: 768px) {
    .layout-container .container .view.register-page {
      margin-left: -5px;
      margin-right: -5px;
      max-width: calc(100% + 10px);
    }
  }
  @media (max-width: 520px) {
    .no-play {
      width: 250px;
    }
  }
  @media (max-width: 370px) {
    .no-play {
      width: 200px;
    }
  }
  @media (max-width: 300px) {
    .no-play {
      width: 170px;
    }
  }

  .table-bingo-player {
    // position: absolute;
    // margin-top: -850px;
    // margin-left: 20%;
    .bingo {
      margin-top: 20px;
      text-align: -webkit-center;
    }
    .container_bingo {
      display: table-cell;
      flex-wrap: wrap;
      margin-top: 0px;
    }
    .cell {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 80px;
      height: 80px;
      border: 1px solid #aaa;
      font-size: 2.2rem;
      font-weight: 700;
      cursor: pointer;
      margin: 1px;
    }

    .cell-leter {
      color: #ffe619;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-weight: 900;
      width: 60px;
      height: 60px;
      margin-right: -1px;
      margin-bottom: -1px;
      font-weight: bold;
      font-size: 4rem;
    }
    .table-bingo {
      color: #ffe619;
      text-align: center;
      font-size: 1.3rem;
      font-weight: 700;
      letter-spacing: 10px;
    }
  }
}
</style>

<style lang="css">
.el-icon-circle-check {
  color: #1cc529;
}
.el-autocomplete-suggestion li {
  line-height: 15px !important;
  padding: 5px 20px 5px;
}
.value_item {
  margin: 0;
  height: 15px;
}
.el-autocomplete {
  width: 100% !important;
}

/* placeholder */
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #d3d3d3;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #d3d3d3;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #d3d3d3;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d3d3d3;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d3d3d3;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #d3d3d3;
}
</style>