import request from '@/utils/request'

export function update(data, id) {
  return request({
    url: 'mailTemplate/update/' + id,
    method: 'put',
    data
  });
}

export function getById(id) {
  return request({
    url: 'mailTemplate/getById/' + id,
    method: 'get'
  });
}

export function getData() {
  return request({
    url: 'mailTemplate/getData',
    method: 'get'
  });

}
