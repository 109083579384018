<!-- =========================================================================================
    File Name: Maintenance.vue
    Description: Maintenance Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="maintenance">
    <div class="msg-box">
      <h1 class="animated fadeInRight title">En mantenimiento</h1>
      <h2 class="animated fadeInRight title2">
        <br />Estamos trabajando para ti, Gracias por su paciencia.
      </h2>
      <img
        src="@/assets/images/pages/maintenance-2.png"
        alt="graphic-maintenance"
        class="mx-auto mb-4 img"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1: "",
    };
  },
};
</script>
<style lang="scss" scoped>
.maintenance {
  text-align: center;
}
.title {
  color: #ffffff !important;
}
.title2 {
  color: #ffee78 !important;
}
.img {
  width: 25%;
}
@media (max-width: 900px) {
  .img {
    width: 50%;
  }
}
@media (max-width: 650px) {
  .img {
    width: 65%;
  }
}
@media (max-width: 430px) {
  .img {
    width: 70%;
  }
}
@media (max-width: 330px) {
  .img {
    width: 80%;
  }
}
@media (max-width: 300px) {
  .img {
    width: 80%;
  }
}
</style>
