<template>
  <div>
    <el-form-item prop="beneficiary1">
      <el-input
        class="form-input"
        type="number"
        v-model="beneficiary1"
        autocomplete="off"
        placeholder="Documento del beneficiario 1"
        @input="sendValue(1)"
      ></el-input>
    </el-form-item>
    <el-form-item prop="beneficiary2">
      <el-input
        class="form-input"
        type="number"
        v-model="beneficiary2"
        autocomplete="off"
        placeholder="Documento del beneficiario 2"
        @input="sendValue(2)"
      ></el-input>
    </el-form-item>
    <!-- <el-form-item prop="beneficiary3">
      <el-input
        class="form-input"
        type="number"
        v-model="beneficiary3"
        autocomplete="off"
        placeholder="Documento del beneficiario 3"
        @input="sendValue(3)"
      ></el-input>
    </el-form-item> -->
  </div>
</template>

<script>
export default {
  name: "Beneficiary",
  props: ['reset'],
  watch: {
    reset: function(val) {
      if(val){
        this.beneficiary1="";
        this.beneficiary2="";
        this.beneficiary3="";
      }
    },
  },
  data() {
    return {
      beneficiary1: "",
      beneficiary2: "",
      beneficiary3: "",
    };
  },
  methods: {
    sendValue(op) {
      let val = this.beneficiary1;
      if (op === 2) {
        val = this.beneficiary2;
      } else {
        if (op === 3) {
          val = this.beneficiary3;
        }
      }
      this.$emit("set-fields", {
        field: "beneficiary" + op,
        value: val,
      });
    },
  },
};
</script>