import request from '@/utils/request'

export function getTableCode(data) {
  return request({
    url: 'bingo/getTableCode',
    method: 'post',
    data
  });
}

export function bingo(id) {
  return request({
    url: 'bingo/bingo/' + id,
    method: 'get'
  });
}

export function deleteTable(id) {
  return request({
    url: 'bingo/deleteTable/' + id,
    method: 'delete'
  });
}

// export function getTables(serial_id) {
//   return request({
//     url: 'bingo/getTables/' + serial_id,
//     method: 'get'
//   });
// }

export function getTables(serial_id, page_size, page, table_number, player_nit, player_name) {
  return request({
    url: 'bingo/getTables/' + serial_id + '/' + page_size + '/' + table_number + '/' + player_nit + '/' + player_name + '?page=' + page,
    method: 'get'
  });
}