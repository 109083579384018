<template>
  <div>
    <el-select
      v-model="city_id"
      clearable
      filterable
      placeholder="Ciudad"
      @change="sendValue"
    >
      <el-option
        v-for="item in cities"
        :key="item.id"
        :label="item.description"
        :value="item.id"
      >
        <span style="float: left"
          ><i class="el-icon-location-information"></i>
          {{ item.description }}
        </span>
        <span style="float: right; color: #8492a6; font-size: 13px">
          ({{ item.state.description }})</span
        >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {
  getCities,
} from "@/api/player";
export default {
  name: "City",
  props: ['reset'],
  watch: {
    reset: function(val) {
      console.log('entro 1',val);
      if(val){
        console.log('entro 2',val);
        this.city_id="";
      }
    },
  },
  created() {
    this.getCity();
  },
  data() {
    return {
      city_id: "",
      cities: [],
    };
  },
  methods: {
    sendValue() {
      this.$emit("set-fields", {
        field: "city_id",
        value: this.city_id,
      });
    },
    getCity() {
      let me = this;
      getCities()
        .then(function (response) {
          me.cities = response.data;
        })
        .catch(function (error) {
          console.log(error);
          toastr.warning("Error: -" + error);
        });
    },
  },
};
</script>


<style lang="scss">
  .el-select {
    width: 100%;
  }
  </style>