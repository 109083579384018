<template>
  <div>
    <el-select v-model="company" placeholder="Empresa" value-key="id" filterable @change="sendValue">
      <el-option v-for="item in companiesCarvajal" :key="item.id" :label="item.name" :value="item.name">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "CompanySelect",
  props: ['reset'],
  watch: {
    reset: function (val) {
      this.company = "";
    },
  },
  created() {
    // ORDENAR ALFABETICAMENTE EL ARREGLO companies
    // this.companies = this.companies.sort(this.SortArray);
    // this.companiesCarvajal = this.companiesCarvajal.sort(this.SortArray);
  },
  data() {
    return {
      company: "",
      companies: [
        { id: 1, name: "Cartago" },
        { id: 2, name: "Tuluá" },
        { id: 3, name: "Buga" },
        { id: 4, name: "Buenaventura" },
        { id: 5, name: "Cali" },
        { id: 6, name: "Palmira" },
      ],
      companiesCarvajal: [
        { id: 1, name: "Carvajal Educación" },
        { id: 2, name: "Carvajal Empaques" },
        { id: 3, name: "Carvajal Espacios" },
        { id: 4, name: "Carvajal Pulpa y Papel (Propal - Zona Franca)" },
        // { id: 4, name: "Carvajal Pulpa y Papel (Propal)" },
        // { id: 5, name: "Carvajal Pulpa y Papel Zona Franca" },
        { id: 6, name: "Carvajal Servicios Compartidos" },
        { id: 7, name: "Carvajal Soluciones de Comunicación" },
        { id: 8, name: "Carvajal Tecnología y Servicios" },
        // { id: 9, name: "Carvajal Servicios (Servinte)" },
        { id: 10, name: "Carvajal Propiedades e Inversiones (Corporativo)" },
        { id: 11, name: "Fundación Carvajal" },
        { id: 12, name: "Fundación Propal" },
        { id: 13, name: "Propandina" },
      ],
      companiesTecnoQuimicas: [
        { id: 1, name: "Constructeq" },
        { id: 2, name: "Fonemptec" },
        { id: 3, name: "Jubilados" },
        { id: 4, name: "Pl Adhinter Cali" },
        { id: 5, name: "Pl Arpack Villarica" },
        { id: 6, name: "Pl Coldesivos Cali" },
        { id: 7, name: "Pl Indugraficas Cali" },
        { id: 8, name: "Pl Tecnocendis" },
        { id: 9, name: "Pl Tecnofar Villarica" },
        { id: 10, name: "Pl Tecnosur Villarica" },
        { id: 11, name: "Pl Tq Jamundi" },
        { id: 12, name: "Pl Tq Yumbo" },
        { id: 13, name: "Ppal San Nicolas Cali Ofic" },
        { id: 14, name: "Rg Antioquia" },
        { id: 15, name: "Rg Atlantico" },
        { id: 16, name: "Rg Ctopacifico Cali" },
        { id: 17, name: "Rg Ctopacifico Dquebrada" },
        { id: 18, name: "Rg Cundicamarca" },
        { id: 19, name: "Rg Oriente" },
      ],
      unidadesBogota: [
        { id: 1, name: "MEBOG" },
        { id: 2, name: "DECUN" },
        { id: 3, name: "DIJIN" },
        { id: 4, name: "DIPOL" },
        { id: 5, name: "DIPRO" },
        { id: 6, name: "DIRAN" },
        { id: 7, name: "DIEPO" },
        { id: 8, name: "DICAR" },
        { id: 9, name: "JESEP" },
        { id: 10, name: "ARCOI" },
        { id: 11, name: "OFPLA" },
        { id: 12, name: "SEGEN" },
        { id: 13, name: "OFITE" },
        { id: 14, name: "COEST" },
        { id: 15, name: "INSGE" },
        { id: 16, name: "DITRA" },
        { id: 17, name: "POLFA" },
        { id: 18, name: "DIASE" },
        { id: 19, name: "DITAH" },
        { id: 20, name: "DILOF" },
        { id: 21, name: "DISAN" },
        { id: 22, name: "DIBIE" },
        { id: 23, name: "DINCO" },
        { id: 24, name: "REGION1" },
        { id: 25, name: "OTROS" }
      ],
    };
  },
  methods: {
    sendValue() {
      this.$emit("set-fields", {
        field: "name_company",
        value: this.company,
      });
    },
    SortArray(x, y) {
      return x.name.localeCompare(y.name);
    },
  },
};
</script>

<style lang="scss">
.el-select {
  width: 100%;
}
</style>