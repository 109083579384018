<template>
  <div>
    <el-card class="card-shadow--small">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            placeholder="Número de tabla"
            size="mini"
            v-model="table_number"
            clearable
            @clear="
              table_number = null;
              getData();
            "
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input
            placeholder="# Documento jugador"
            size="mini"
            v-model="player_nit"
            clearable
            @clear="
              player_nit = null;
              getData();
            "
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            :loading="loadingFilter"
            @click="filterTable"
            >Buscar</el-button
          >
        </el-col>
        <el-col :span="8">
          <el-button
            style="float: right"
            icon="el-icon-upload"
            size="mini"
            @click="openUpload = true"
            v-if="showUploadTables"
            >Cargar Tablas</el-button
          >
          <el-button
            type="danger"
            style="float: right"
            icon="el-icon-delete"
            size="mini"
            @click="deleteTablesSerial"
            v-if="showDeleteTables"
            >Borrar Tablas</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-table
      :data="tableData"
      v-loading="loading"
      height="600"
      style="width: 100%"
    >
      <el-table-column
        prop="serial.serial"
        label="Serial"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="table_bingo"
        label="Tabla"
        width="120"
      ></el-table-column>
      <el-table-column prop="json" label="Números de tabla">
        <template slot-scope="scope">
          <pre>{{ converJsonTables(scope.row) }}</pre>
        </template>
      </el-table-column>
      <el-table-column prop="pivot" label="Jugador / Ciudad" width="300">
        <template slot-scope="scope">
          <div v-if="scope.row.pivot !== null">
            <i class="el-icon-user"></i>
            {{ scope.row.pivot.players.name_player }}
          </div>
          <div v-if="scope.row.pivot !== null">
            <i class="el-icon-location-information"></i>
            {{ scope.row.pivot.players.city.description }}
            <small
              >({{ scope.row.pivot.players.city.state.description }})</small
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="selected" label="Estado" width="130">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.selected !== 1 ? 'success' : 'primary'"
            disable-transitions
          >
            <i class="el-icon-s-grid"></i>
            {{ scope.row.selected === 1 ? "Asignada" : "Discponible" }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div
      v-if="tableData.length > 0"
      class="pagination-box"
      style="text-align: end; padding-top: 10px"
    >
      <el-pagination
        layout="slot, total, prev, pager, next"
        :page-size="pageSize"
        :total="total_rows"
        @current-change="handleCurrentPage"
      >
        <!-- <total>Mostrando {{ pageSize }} registros (Total: {{ total_rows }}) </total> -->
      </el-pagination>
    </div>
    <modal-upload-file
      :modalOpen="openUpload"
      :serial_id="serial_id"
      @close="openUpload = false"
      @refresh="getData()"
    ></modal-upload-file>
  </div>
</template>
<script>
import { getTables, deleteTable } from "@/api/bingo";
import { deleteTables } from "@/api/serial";
import ModalUploadFile from "../../../views/apps/modules/ModalUploadFile.vue";
export default {
  name: "TablessList",
  props: ["show", "serial_id"],
  watch: {
    show: function (val) {
      if (val) {
        this.getData();
      }
    },
  },
  components: {
    ModalUploadFile,
  },
  data() {
    return {
      tableData: [],
      list: [],
      loading: false,
      loadingFilter: false,
      showUploadTables: false,
      showDeleteTables: false,
      openUpload: false,
      search: "",
      //
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
      // filter
      table_number: null,
      player_nit: null,
      player_name: null,
      //
      openUpload: false,
    };
  },
  methods: {
    deleteTablesSerial() {
      this.$swal
        .fire({
          target: "body",
          icon: "warning",
          title: "Estas seguro de eliminar todas las tablas de este serial?",
          html:
            '<strong style="color: #d41d56">No podras deshacer la eliminación!</strong>',
          showCancelButton: true,
          confirmButtonText: `Si, borrar`,
          cancelButtonText: `No, cancelar`,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          focusCancel: true,
        })
        .then((result) => {
          if (result.value) {
            deleteTables(this.serial_id)
              .then(({ data }) => {
                this.getData();
                this.$swal.fire(
                  "Eliminación de tablas exitosa!",
                  "",
                  "success"
                );
              })
              .catch(function (error) {
                console.log(error);
                this.loading = false;
              });
          }
        });
    },
    converJsonTables(row) {
      var json = JSON.parse(row.json);
      var b = "B: " + json["b"].toString();
      var i = "I: " + json["i"].toString();
      var n = "N: " + json["n"].toString();
      var g = "G: " + json["g"].toString();
      var o = "O: " + json["o"].toString();
      var bingo = b + "\n" + i + "\n" + n + "\n" + g + "\n" + o;
      return bingo;
    },
    filterTable() {
      this.loadingFilter = true;
      this.getData();
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getData();
    },
    getData() {
      this.loading = true;
      if (
        this.table_number != null ||
        this.player_nit != null ||
        this.player_name != null
      ) {
        this.currentPage = 1;
      }
      getTables(
        this.serial_id,
        this.pageSize,
        this.currentPage,
        this.table_number,
        this.player_nit,
        this.player_name
      )
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loading = false;
          this.loadingFilter = false;
          if (data.data.length === 0) {
            this.showUploadTables = true;
            this.showDeleteTables = false;
          } else {
            this.showUploadTables = false;
            this.showDeleteTables = true;
          }
        })
        .catch(function (error) {
          this.loadingFilter = false;
          this.loading = false;
          console.log(error);
        });
    },
    deleteTable(row) {
      this.loading = true;
      deleteTable(row.id)
        .then(({ data }) => {
          this.getData();
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.show) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";
</style>