<template>
  <div>
    <el-input
      class="form-input"
      type="text"
      v-model="nameCountry"
      autocomplete="off"
      placeholder="País"
      @input="sendValue"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: "Country",
  props: ['reset'],
  data() {
    return {
      nameCountry: "",
    };
  },
  methods: {
    sendValue() {
      this.$emit("set-fields", {
        field: "country",
        value: this.nameCountry,
      });
    },
  },
};
</script>

<style lang="scss">
.el-select {
  width: 100%;
}
</style>