<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>
        <i class="el-icon-user"></i> Registro de usuarios
      </span>
    </div>
    <el-row :gutter="20">
      <el-col :span="12">
        <!-- <img class="image-logo" src="@/assets/images/logo.svg" alt="logo" /> -->
        <el-form
          :model="form"
          :rules="rules"
          size="small"
          ref="form"
          class="demo-form"
          status-icon
          v-loading="loading"
        >
          <el-form-item prop="name">
            <float-label class="styled">
              <input type="text" placeholder="Nombre" v-model="form.name" />
            </float-label>
          </el-form-item>
          <el-form-item prop="email">
            <float-label class="styled">
              <input type="email" placeholder="E-mail" v-model="form.email" />
            </float-label>
          </el-form-item>
          <el-form-item prop="password">
            <float-label class="styled">
              <input type="password" placeholder="Contraseña" v-model="form.password" />
            </float-label>
          </el-form-item>
          <el-form-item prop="confirm_password">
            <float-label class="styled">
              <input
                type="password"
                placeholder="Repetir Contraseña"
                v-model="form.confirm_password"
              />
            </float-label>
          </el-form-item>
        </el-form>

        <div class="flex pt-10 pb-20">
          <el-button
            plain
            size="small"
            type="success"
            icon="el-icon-check"
            @click="save('form')"
            class="signin-btn tada animated"
          >Guardar</el-button>
        </div>
      </el-col>
      <el-col :span="12">
        <el-table :data="tableData" v-loading="loading_table" height="400" style="width: 100%">
          <el-table-column prop="name" label="Usuario"></el-table-column>
          <el-table-column prop="email" label="Email"></el-table-column>
          <!-- <el-table-column label="Acción" width="110">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="warning"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
              >Editar</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import { getUser, setToken, setUser, removeBingo } from "@/utils/auth";
import { store , getUsers} from "@/api/user";
import { login } from "@/api/login";
export default {
  name: "Register",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Porfavor ingrese la contraseña"));
      } else {
        if (this.form.confirm_password !== "") {
          this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Porfavor repita la contraseña"));
      } else if (value !== this.form.password) {
        callback(new Error("Las contraseñas no coinciden!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      loading_table: false,
      tableData: [],
      form: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
      },
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        confirm_password: [{ validator: validatePass2, trigger: "blur" }],
        name: [
          {
            required: true,
            message: "Porfavor ingrese el nombre",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Porfavor ingrese el email",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Email invalido",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    save(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveForm();
        } else {
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveForm() {
      let me = this;
      store(this.form)
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 200) {
            this.$message({
              type: "success",
              message: "Registro exitoso",
            });
            this.loading_table = true;
            me.resetForm("form");
            me.getData()
            // setTimeout(() => {
            //   me.loading_table = false;
            //   me.loginUser(me.form.email, me.form.password);
            // }, 2000);
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function (error) {
          this.loading = false;
          console.log("Err: ", error);
        });
    },
    loginUser(email, pass) {
      this.loading = true;
      if (email != "" && pass != "") {
        login(email, pass, false)
          .then(({ data }) => {
            if (data.code === 200) {
              setToken(data.access_token);
              setUser(data.user);
              removeBingo();
              let user = JSON.parse(data.user);
              this.$store.commit("setLogin");
              for (var i = 0; i < user.roles.length; i++) {
                if (user.roles[i].redirect_to) {
                  location.reload();
                } else {
                  this.$router.push({ path: "/404" });
                }
              }
            } else {
              this.error = true;
              this.errorMsg = "Credenciales invalidas";
            }
            this.loading = false;
          })
          .catch((response) => {
            this.error = true;
            this.loading = false;
            // this.errorMsg = error.errors
            console.log(response, "asdf");
          });
      } else {
        this.error = true;
        this.errorMsg = "Compelte los campos por favor";
        this.loading = false;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loading = false;
    },
    getData() {
      getUsers()
        .then(({ data }) => {
          this.tableData = data;
          this.loading_table = false;
        })
        .catch(function (error) {
          this.loading_table = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.loading_table = true;
    this.getData();
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";

.register-page {
  margin-left: -30px;
  margin-right: -30px;

  .accept {
    margin-top: 20px;
  }
  .form-wrapper {
    width: 100%;
  }

  .form-box {
    width: 100%;
    max-width: 340px;
    padding: 30px;
    box-sizing: border-box;
    margin: 20px auto;

    a {
      font-size: 14px;
      color: $text-color-accent;
      text-decoration: none;
      font-weight: 500;
    }

    .image-logo {
      width: 80px;
      margin: 0px auto;
      // margin-bottom: 50px;
      display: block;
    }

    .signin-btn {
      width: 160px;
    }

    .login-box {
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .layout-container .container .view.register-page {
    margin-left: -5px;
    margin-right: -5px;
    max-width: calc(100% + 10px);
  }
}
</style>
