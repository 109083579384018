<template>
  <div class="page-ecommerce-products flex">
    <div :class="{ sidebar: true, open: sidebarOpen }">
      <vue-scroll class="scroller">
        <div class="widget close-filter-box">
          <button @click="sidebarOpen = false">Cerrar Menu</button>
        </div>
        <div class="widget">
          <div class="title">Menu</div>
          <div class="content">
            <el-row class="tac">
              <el-col :span="24">
                <el-menu
                  default-active="1"
                  class="el-menu-vertical-demo"
                  @open="handleOpen"
                  @close="handleClose"
                >
                  <template v-for="item in menu">
                    <el-menu-item
                      v-if="showItem(item)"
                      v-bind:key="item.id"
                      @click="showMenu(item.showComponent)"
                      :index="item.index"
                    >
                      <i :class="item.icon"></i>
                      <span>{{ item.name }}</span>
                    </el-menu-item>
                  </template>
                </el-menu>
              </el-col>
            </el-row>
          </div>
        </div>
      </vue-scroll>
    </div>

    <div class="list-container box grow flex column">
      <div class="toggle-filter-box">
        <button @click="sidebarOpen = !sidebarOpen">
          <span v-if="!sidebarOpen">Abrir Menu</span>
          <span v-if="sidebarOpen">Cerrar Menu</span>
        </button>
      </div>

      <div class="list scrollable only-y box grow">
        <serial-list v-if="showSerials" :show="showSerials"></serial-list>
        <tables-list v-if="showTable" :show="showTable"></tables-list>
        <players-list v-if="showPlayers" :show="showPlayers"></players-list>
        <mail-template
          v-if="showEmailTemplate"
          :show="showEmailTemplate"
        ></mail-template>
        <register v-if="showRegister" :show="showRegister"></register>
      </div>
    </div>
  </div>
</template>

<script>
import { getUser } from "@/utils/auth";
import SerialList from "../../../views/apps/modules/SerialList.vue";
import TablesList from "../../../views/apps/modules/TablesList.vue";
import PlayersList from "../../../views/apps/modules/PlayersList.vue";
import MailTemplate from "../../../views/apps/modules/MailTemplate.vue";
import Register from "../../../views/pages/authentication/Register.vue";
export default {
  name: "Administration",
  components: {
    SerialList,
    TablesList,
    PlayersList,
    MailTemplate,
    Register,
  },
  data() {
    return {
      sidebarOpen: false,
      showTable: false,
      showPlayers: false,
      showSerials: true,
      showEmailTemplate: false,
      showRegister: false,
      menu: [
        {
          id: 1,
          index: "1",
          name: "Juegos",
          icon: "el-icon-trophy-1",
          showComponent: "serials",
          meta: {
            nav: true,
            roles: ["admin","supervisor", "support"],
          },
        },
        {
          id: 2,
          index: "2",
          name: "Jugadores Registrados",
          icon: "el-icon-user",
          showComponent: "players",
          meta: {
            nav: true,
            roles: ["admin","supervisor", "support"],
          },
        },
        {
          id: 3,
          index: "3",
          name: "Plantillas Email",
          icon: "el-icon-message",
          showComponent: "emailTemplate",
          meta: {
            nav: true,
            roles: ["admin","supervisor", "support"],
          },
        },
        {
          id: 4,
          index: "4",
          name: "Usuarios",
          icon: "el-icon-user",
          showComponent: "registerUser",
          meta: {
            nav: true,
            roles: ["admin"],
          },
        },
      ],
    };
  },
  computed: {},
  methods: {
    showItem(item) {
      let res = false;
      if (item.meta && item.meta.nav) {
        res = true;
        if (item.meta.roles) {
          res = this.show(item.meta.roles);
        }
      }
      return res;
    },
    show(data) {
      let roles = getUser("roles");
      let res = false;
      for (var i = 0; i < roles.length; i++) {
        if (data.includes(roles[i].guard_name)) {
          res = true;
        }
      }
      return res;
    },
    showMenu(op) {
      if (op === "serials") {
        this.showTable = false;
        this.showPlayers = false;
        this.showSerials = true;
        this.showEmailTemplate = false;
        this.showRegister = false;
      }
      if (op === "players") {
        this.showTable = false;
        this.showPlayers = true;
        this.showSerials = false;
        this.showEmailTemplate = false;
        this.showRegister = false;
      }
      if (op === "tables") {
        this.showTable = true;
        this.showPlayers = false;
        this.showSerials = false;
        this.showEmailTemplate = false;
        this.showRegister = false;
      }
      if (op === "emailTemplate") {
        this.showTable = false;
        this.showPlayers = false;
        this.showSerials = false;
        this.showEmailTemplate = true;
        this.showRegister = false;
      }
      if (op === "registerUser") {
        this.showTable = false;
        this.showPlayers = false;
        this.showSerials = false;
        this.showEmailTemplate = false;
        this.showRegister = true;
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  created() {
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/apps/administration";
</style>

<style lang="css">
.el-input--small .el-input__inner {
  height: 28px;
  line-height: 28px;
  font-size: 14px;
}
.el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
</style>


