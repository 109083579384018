<template>
  <div>
    <el-dialog
      width="23%"
      top="40px"
      :visible.sync="modalOpenCreatePlayers"
      :before-close="closeModal"
      :destroy-on-close="true"
    >
      <div slot="title">
        <i class="el-icon-circle-plus-outline"></i> Crear jugadores a partir de
        archivo de validación
      </div>
      <el-alert
        title="Se crearán los jugadores con los datos del archivo que ha sido cargado para validar datos"
        type="info"
        show-icon
        style="margin-bottom: 10px"
      >
      </el-alert>
      <!-- el-icon-tickets -->
      <el-form ref="form" label-width="0px" class="demo-form">
        <el-form-item label="" prop="serial">
          <el-button
            type="primary"
            icon="el-icon-user"
            size="medium"
            round
            :loading="loading"
            @click="createPlayers"
            >Click para crear jugadores</el-button
          >
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" icon="el-icon-close" @click="closeModal"
          >Cancelar</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getSerialById, createPlayers } from "@/api/serial";
export default {
  name: "ModalSettings",
  props: ["modalOpenCreatePlayers", "serial_id", "config"],
  watch: {
    serial_id: function (val) {
      if (val) {
        this.getSerial();
      }
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    createPlayers() {
      this.loading = true;
      createPlayers(this.serial_id)
        .then(({ data }) => {
          this.loading = false;
          this.$message({
            type: "success",
            message: "Creación de jugadores Exitosa",
          });
          this.$emit("refresh");
          console.log("data:", data);
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    getSerial() {
      this.loading = true;
      getSerialById(this.serial_id)
        .then(({ data }) => {
          let configTables = JSON.parse(data.config_table);
          console.log(configTables);
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
        });
    },
    closeModal() {
      this.$emit("close-create-players");
    },
  },
};
</script>
<style lang="scss" scope>
.line {
  text-align: center;
}
.mb-0 {
  margin-bottom: 0 !important;
}
</style>