const Player = 'player'
const Bingo = 'Bingo'


export function setToken(token) {
  return sessionStorage.setItem(Token, token)
}

export function getPlayer(param) {
  var data = sessionStorage.getItem(Player)
  data = JSON.parse(data)
  if (param && data) {
    return data[param]
  }
  return data
}

export function setPlayer(player) {
  sessionStorage.setItem(Player, player)
}

export function getBingoStorage() {
  return sessionStorage.getItem(Bingo)
}

export function setBingoStorage(bingo) {
  return sessionStorage.setItem(Bingo,JSON.stringify(bingo))
}
// REMOVE PLAYER

export function removePlayer() {
  return sessionStorage.removeItem(Player)
}

export function removeBingo() {
  return sessionStorage.removeItem(Bingo)
}
