<template>
  <el-dialog
    title="Cargar Tablas"
    width="30%"
    :visible.sync="modalOpen"
    :before-close="handleClose"
    :append-to-body="true"
    :destroy-on-close="true"
  >
    <el-upload
      class="upload-file"
      drag
      ref="upload"
      :limit="1"
      :action="baseURL"
      :auto-upload="false"
      :on-success="submitComplete"
      :on-error="submitError"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        Suelta tu archivo aquí o <em>haz clic para cargar</em>
      </div>
      <!-- <el-button slot="trigger" size="small" type="primary">Selecciona un archivo</el-button> -->

      <div slot="tip" class="el-upload__tip">Solo archivos xlsx</div>
    </el-upload>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal()" icon="el-icon-close" size="small">Cancelar</el-button>
      <el-button
        style="margin-left: 10px"
        size="small"
        type="success"
        icon="el-icon-upload"
        @click="submitUpload"
        :loading="loading"
        >Cargar datos</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: ["modalOpen", "serial_id"],
  data() {
    return {
      baseURL: "",
      loading: false,
    };
  },
  methods: {
    submitUpload() {
      let me = this;
      this.loading = true;
      this.baseURL =
        process.env.VUE_APP_ROOT_API + "/bingo/uploadFile/" + this.serial_id;
      setTimeout(() => {
        me.$refs.upload.submit();
      }, 1000);
    },
    submitComplete(response, file, fileList) {
      this.loading = false;
      if (response.code === 200) {
        this.$message({
          type: "success",
          message: "Carga Exitosa",
        });
        this.$emit("refresh");
        this.closeModal();
      } else {
        this.$message({
          type: "warning",
          message: "Error: " + response.error.errorInfo[2],
        });
      }
    },
    submitError(err, file, fileList) {
      this.loading = true;
      console.log(err);
    },
    closeModal() {
      this.$emit("close");
    },
    handleClose(done) {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
  .upload-file{
    text-align: center;
  }
</style>