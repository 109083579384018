<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>
        <i class="el-icon-message"></i> Plantillas de email
      </span>
    </div>
    <el-row :gutter="20">
      <el-col :span="16">
        <el-form
          :model="form"
          :rules="rules"
          size="small"
          ref="form"
          class="demo-form"
          status-icon
          v-loading="loadingForm"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="name_template" label="Nombre de Plantilla">
                <el-input
                  class="form-input"
                  type="text"
                  v-model="form.name_template"
                  autocomplete="off"
                  placeholder="Nombre"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="subject" label="Tema">
                <el-input
                  class="form-input"
                  type="text"
                  v-model="form.subject"
                  autocomplete="off"
                  placeholder="Tema (subject)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item prop="messagge" label="Mensaje">
                <el-tiptap
                  v-model="form.messagge"
                  :extensions="textExtensions"
                  placeholder="Diseña tu plantilla..."
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button
              icon="el-icon-edit"
              type="warning"
              @click="save('form')"
              v-if="form.id"
            >Actualizar</el-button>
            <el-button icon="el-icon-close" @click="resetForm('form')">Cancelar</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="8">
        <el-table :data="tableData" v-loading="loading" height="250" style="width: 100%">
          <el-table-column prop="name_template" label="Plantilla"></el-table-column>
          <el-table-column label="Acción" width="110">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="warning"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
              >Editar</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>
              <i class="el-icon-edit"></i> Variables para usar
            </span>
          </div>
          <div class="item-variable">
            - Nombre del jugador <br>
            <span style="font-weight: bold;" class="name_varialbe">{player_name}</span>
            <el-tooltip class="item" effect="dark" content="Copiar" placement="right">
              <el-button
                circle
                class="fr"
                size="mini"
                type="primary"
                v-clipboard:copy="'{player_name}'"
                v-clipboard:success="clipboardSuccess"
                icon="el-icon-document-copy"
              ></el-button>
            </el-tooltip>
          </div>
          <div class="item-variable">
            - Número de tabla <br>
            <span style="font-weight: bold;" class="name_varialbe">{table_number}</span>
            <el-tooltip class="item" effect="dark" content="Copiar" placement="right">
              <el-button
                circle
                class="fr"
                size="mini"
                type="primary"
                v-clipboard:copy="'{table_number}'"
                v-clipboard:success="clipboardSuccess"
                icon="el-icon-document-copy"
              ></el-button>
            </el-tooltip>
          </div>
          <div class="item-variable">
            - Fecha del juego <br>
            <span style="font-weight: bold;" class="name_varialbe">{date}</span>
            <el-tooltip class="item" effect="dark" content="Copiar" placement="right">
              <el-button
                circle
                class="fr"
                size="mini"
                type="primary"
                v-clipboard:copy="'{date}'"
                v-clipboard:success="clipboardSuccess"
                icon="el-icon-document-copy"
              ></el-button>
            </el-tooltip>
          </div>
          <div class="item-variable">
            - Hora del juego <br>
            <span style="font-weight: bold;" class="name_varialbe">{time}</span>
            <el-tooltip class="item" effect="dark" content="Copiar" placement="right">
              <el-button
                circle
                class="fr"
                size="mini"
                type="primary"
                v-clipboard:copy="'{time}'"
                v-clipboard:success="clipboardSuccess"
                icon="el-icon-document-copy"
              ></el-button>
            </el-tooltip>
          </div>
          <div class="item-variable">
            - URL de transmision <br>
            <span style="font-weight: bold;" class="name_varialbe">{url_transmission}</span>
            <el-tooltip class="item" effect="dark" content="Copiar" placement="right">
              <el-button
                circle
                class="fr"
                size="mini"
                type="primary"
                v-clipboard:copy="'{url_transmission}'"
                v-clipboard:success="clipboardSuccess"
                icon="el-icon-document-copy"
              ></el-button>
            </el-tooltip>
          </div>
          <div class="item-variable">
            - Código de confirmación de email <br>
            <span style="font-weight: bold;" class="name_varialbe">{confirmation_code}</span>
            <el-tooltip class="item" effect="dark" content="Copiar" placement="right">
              <el-button
                circle
                class="fr"
                size="mini"
                type="primary"
                v-clipboard:copy="'{confirmation_code}'"
                v-clipboard:success="clipboardSuccess"
                icon="el-icon-document-copy"
              ></el-button>
            </el-tooltip>
          </div>
          <div class="item-variable">
            - URL de confirmación de email <br>
            <span style="font-weight: bold;" class="name_varialbe">{url_confirmation}</span>
            <el-tooltip class="item" effect="dark" content="Copiar" placement="right">
              <el-button
                circle
                class="fr"
                size="mini"
                type="primary"
                v-clipboard:copy="'{url_confirmation}'"
                v-clipboard:success="clipboardSuccess"
                icon="el-icon-document-copy"
              ></el-button>
            </el-tooltip>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  // text extensions
  Bold,
  Underline,
  Italic,
  Strike,
  Code,
  FontType,
  FontSize,
  TextColor,
  TextHighlight,
  FormatClear,
  // paragraph extensions
  Heading,
  ListItem,
  BulletList,
  OrderedList,
  TodoItem,
  TodoList,
  TextAlign,
  LineHeight,
  Indent,
  Blockquote,
  CodeBlock,
  // rich and tools extensions
  Link,
  Image,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Iframe,
  TrailingNode,
  HorizontalRule,
  Fullscreen,
  Print,
  Preview,
  SelectAll,
  History,
  CodeView,
} from "element-tiptap";
import codemirror from "codemirror";
import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/mode/xml/xml.js"; // language
import "codemirror/addon/selection/active-line.js"; // require active-line.js
import "codemirror/addon/edit/closetag.js"; // autoCloseTags

import clipboard from "@/directives/clipboard/index.js";

import { getData, getById, update } from "@/api/mailTemplate";
export default {
  name: "EmailTemplate",
  props: ["show"],
  watch: {
    show: function (val) {
      if (val) {
        this.getData();
      }
    },
  },
  directives: {
    clipboard,
  },
  data() {
    return {
      inputData: "fdfsdf",
      tableData: [],
      loading: false,
      loadingForm: false,
      form: {
        name_template: "",
        subject: "",
        messagge: "",
      },
      rules: {
        name_template: [
          { required: true, message: "Por favor ingrese su nombre completo" },
        ],
        subject: [
          { required: true, message: "Por favor ingrese su nombre completo" },
        ],
      },
      textExtensions: [
        new Doc(),
        new Heading({ level: 5 }),
        new Text(),
        new Paragraph(),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike({ bubble: true }),
        new Code(),
        new FontType(),
        new FontSize(),
        new TextColor({ bubble: true }),
        new TextHighlight({ bubble: true }),
        new FormatClear(),
        // paragraph
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new TextAlign(),
        new LineHeight(),
        new Indent(),
        new Blockquote(),
        new CodeBlock(),
        // tools
        new Link(),
        new Image(),
        new Iframe(),
        new Table({ resizable: true }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new HorizontalRule(),
        new Print(),
        new Preview(),
        new SelectAll(),
        new Fullscreen(),
        new CodeView({
          codemirror,
          codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true,
          },
        }),
        new TrailingNode(),
        new History(),
      ],
      // editor's content
      content: "",
    };
  },
  methods: {
    clipboardSuccess() {
      this.$message({
        message: "Copiado exitosamente",
        type: "success",
        duration: 1500,
      });
    },
    save(formName) {
      this.loadingForm = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveForm();
        } else {
          this.loadingForm = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveForm() {
      update(this.form, this.form.id)
        .then(({ data }) => {
          this.getData();
          this.resetForm("form");
          this.loadingForm = false;
          this.$message({
            type: "success",
            message: "Actualización Exitosa!",
          });
        })
        .catch(function (error) {
          this.loadingForm = false;
          this.$message.error("Error! " + error);
          console.log(error);
        });
    },
    getData() {
      this.loading = true;
      getData()
        .then(({ data }) => {
          this.tableData = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form.id = false;
      this.loading = false;
    },
    handleEdit(index, row) {
      this.loadingForm = true;
      getById(row.id)
        .then(({ data }) => {
          this.form = data;
          this.loadingForm = false;
        })
        .catch(function (error) {
          this.loadingForm = false;
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.show) {
      this.getData();
    }
  },
};
</script>
<style lang="scss">
.item-variable {
  margin-bottom: 12px;
  border-bottom: 1px solid gainsboro;
}
.fr {
  float: right;
  margin-top: -15px;
}
.name_varialbe{
  padding-left: 10px;
}
</style>